import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Casts } from 'mobx-spine';
import { Integration } from './Integration'

export class ExactGlobeIntegration extends Model {
  static backendResourceName = 'exact_globe_integration'
  static omitFields = ['active', 'serviceActive']

  @observable id = null
  @observable username = ''
  @observable password = null
  @observable baseUrl = null
  @observable serverName = ''
  @observable databaseName = ''

  @observable syncStartDate = null

  @observable item = false
  @observable account = false
  @observable salesOrder = false
  @observable purchaseOrder = false
  @observable warehouse = false
  @observable production = false
  @observable contactPerson = false
  @observable document = false
  @observable project = false
  @observable resource = false
  @observable warehouseStock = false
  @observable locationStock = false
  @observable stockCount = false
  @observable warehouseTransfer = false
  @observable productionOrderReceipt = false
  @observable purchaseOrderReceipt = false
  @observable goodsDelivery = false
  @observable assemblyBom = false
  @observable stockDifference = false


  // Immediatly when we create an integration, it becomes active
  @observable active = true
  @observable serviceActive = true

  relations() {
    return {
      integration: Integration,
    }
  }

  casts() {
    return {
      syncStartDate: Casts.date,
    }
}
}

export class ExactGlobeIntegrationStore extends Store {
  static backendResourceName = 'exact_globe_integration'
  Model = ExactGlobeIntegration
}
