import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { Warehouse } from './Warehouse'
import { StorageLocation } from './StorageLocation'

export class StockDifference extends Model {
  static backendResourceName = 'stock_difference'
  static idPrefix = 'SD'
  static forcedBackgroundColor = '#ab013c' // red-brown
  static forcedHoverColor = '#9a0136' // %10 darker
  static idIcon = 'balance scale'

  @observable id = null

  relations() {
    return {
      productionRequest: ProductionRequest,
      warehouse: Warehouse,
      defaultStorageLocation: StorageLocation,
    }
  }
}

export class StockDifferenceStore extends Store {
  Model = StockDifference
  static backendResourceName = 'stock_difference'
}
